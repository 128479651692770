<template>
  <div ref="container" :style="containerStyleBind" />
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue'
import { colorToLottie } from '../../libs/colors'
import lottie from 'lottie-web'

export default defineComponent({
  name: 'FlamingoLoader',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: [String, Number],
      default: 64,
    },
  },
  setup(props) {
    const regex = /^-?\d*\.?\d+$/
    const color = computed(() => props.color)
    const lottieColor = computed(() => colorToLottie(color.value))
    const animationData = computed(() => {
      return {
        v: '5.5.8',
        fr: 30,
        ip: 0,
        op: 60,
        w: 100,
        h: 100,
        nm: 'OCR_Scan_18',
        ddd: 0,
        assets: [],
        layers: [
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Bottom Line',
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
              },
              p: {
                a: 0,
                k: [50, 50, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-20.5, 13],
                          [-9.25, 12.75],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: {
                      a: 0,
                      k: 0,
                      ix: 1,
                    },
                    e: {
                      a: 0,
                      k: 100,
                      ix: 2,
                    },
                    o: {
                      a: 0,
                      k: 0,
                      ix: 3,
                    },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: lottieColor.value,
                      ix: 3,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4,
                    },
                    w: {
                      a: 0,
                      k: 4,
                      ix: 5,
                    },
                    lc: 2,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [0, 0],
                      ix: 2,
                    },
                    a: {
                      a: 0,
                      k: [0, 0],
                      ix: 1,
                    },
                    s: {
                      a: 0,
                      k: [100, 100],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7,
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4,
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5,
                    },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 0,
                  k: 0,
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 20,
                      s: [0],
                    },
                    {
                      t: 30,
                      s: [100],
                    },
                  ],
                  ix: 2,
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3,
                },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 0,
            op: 61,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Middle Line',
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
              },
              p: {
                a: 0,
                k: [53.25, 50, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [120, 100, 100],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-20, -0.75],
                          [-2, -1],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: lottieColor.value,
                      ix: 3,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4,
                    },
                    w: {
                      a: 0,
                      k: 4,
                      ix: 5,
                    },
                    lc: 2,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [0, 0],
                      ix: 2,
                    },
                    a: {
                      a: 0,
                      k: [0, 0],
                      ix: 1,
                    },
                    s: {
                      a: 0,
                      k: [100, 100],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7,
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4,
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5,
                    },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 0,
                  k: 0,
                  ix: 1,
                },
                e: {
                  a: 0,
                  k: 100,
                  ix: 2,
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3,
                },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 0,
                  k: 0,
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 10,
                      s: [0],
                    },
                    {
                      t: 20,
                      s: [100],
                    },
                  ],
                  ix: 2,
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3,
                },
                m: 1,
                ix: 3,
                nm: 'Trim Paths 2',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 0,
            op: 61,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Top LIne',
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
              },
              p: {
                a: 0,
                k: [48.25, 50, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [93.75, 100, 100],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-20, -14.5],
                          [18, -14.5],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: lottieColor.value,
                      ix: 3,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4,
                    },
                    w: {
                      a: 0,
                      k: 4,
                      ix: 5,
                    },
                    lc: 2,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [0, 0],
                      ix: 2,
                    },
                    a: {
                      a: 0,
                      k: [0, 0],
                      ix: 1,
                    },
                    s: {
                      a: 0,
                      k: [100, 100],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7,
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4,
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5,
                    },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 0,
                  k: 0,
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 0,
                      s: [0],
                    },
                    {
                      t: 10,
                      s: [100],
                    },
                  ],
                  ix: 2,
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3,
                },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 0,
            op: 61,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Tick',
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
              },
              p: {
                a: 0,
                k: [50.5, 50.74999999999999, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [7.375, 13.875],
                          [13, 19.875],
                          [24.125, 9],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: lottieColor.value,
                      ix: 3,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4,
                    },
                    w: {
                      a: 0,
                      k: 4,
                      ix: 5,
                    },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [0, 0],
                      ix: 2,
                    },
                    a: {
                      a: 0,
                      k: [0, 0],
                      ix: 1,
                    },
                    s: {
                      a: 0,
                      k: [100, 100],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7,
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4,
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5,
                    },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 0,
                  k: 0,
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 45,
                      s: [0],
                    },
                    {
                      t: 55,
                      s: [100],
                    },
                  ],
                  ix: 2,
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3,
                },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 0,
            op: 61,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Circle',
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
              },
              p: {
                a: 0,
                k: [50, 50, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [9.841, 0],
                          [0, -9.841],
                          [-9.841, 0],
                          [0, 9.841],
                        ],
                        o: [
                          [-9.841, 0],
                          [0, 9.841],
                          [9.841, 0],
                          [0, -9.841],
                        ],
                        v: [
                          [17.25, -29.819],
                          [-0.569, -12],
                          [17.25, 5.819],
                          [35.069, -12],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: lottieColor.value,
                      ix: 3,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 4,
                    },
                    w: {
                      a: 0,
                      k: 3,
                      ix: 5,
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [-1.181, 27.569],
                      ix: 2,
                    },
                    a: {
                      a: 0,
                      k: [0, 0],
                      ix: 1,
                    },
                    s: {
                      a: 0,
                      k: [100, 100],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7,
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4,
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5,
                    },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 35,
                      s: [100],
                    },
                    {
                      t: 45,
                      s: [0],
                    },
                  ],
                  ix: 1,
                },
                e: {
                  a: 0,
                  k: 100,
                  ix: 2,
                },
                o: {
                  a: 0,
                  k: 0,
                  ix: 3,
                },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 0,
            op: 61,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Layer 1 Outlines',
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
              },
              p: {
                a: 0,
                k: [49, 49, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [33.045, 33.045, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -1.393],
                          [1.394, 0],
                          [0, 0],
                          [0, 1.394],
                          [0, 0],
                          [-1.394, 0],
                          [0, -1.392],
                        ],
                        o: [
                          [0, 0],
                          [1.394, 0],
                          [0, 1.394],
                          [0, 0],
                          [-1.394, 0],
                          [0, 0],
                          [0, -1.392],
                          [1.393, 0],
                          [0, 0],
                        ],
                        v: [
                          [-27.75, 27.75],
                          [-13.455, 27.75],
                          [-10.932, 30.272],
                          [-13.455, 32.795],
                          [-30.272, 32.795],
                          [-32.795, 30.272],
                          [-32.795, 13.454],
                          [-30.272, 10.932],
                          [-27.75, 13.454],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 1.393],
                          [-1.392, 0],
                          [0, 0],
                          [0, -1.394],
                          [0, 0],
                          [1.394, 0],
                          [0, 1.394],
                        ],
                        o: [
                          [0, 0],
                          [-1.392, 0],
                          [0, -1.394],
                          [0, 0],
                          [1.394, 0],
                          [0, 0],
                          [0, 1.394],
                          [-1.393, 0],
                          [0, 0],
                        ],
                        v: [
                          [27.75, -27.75],
                          [13.454, -27.75],
                          [10.932, -30.272],
                          [13.454, -32.795],
                          [30.272, -32.795],
                          [32.795, -30.272],
                          [32.795, -13.455],
                          [30.272, -10.932],
                          [27.75, -13.455],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 2,
                    ty: 'sh',
                    ix: 3,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [1.393, 0],
                          [0, 1.394],
                          [0, 0],
                          [-1.394, 0],
                          [0, 0],
                          [0, -1.394],
                          [1.394, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 1.394],
                          [-1.394, 0],
                          [0, 0],
                          [0, -1.394],
                          [0, 0],
                          [1.394, 0],
                          [0, 1.393],
                          [0, 0],
                        ],
                        v: [
                          [-27.75, -27.75],
                          [-27.75, -13.455],
                          [-30.272, -10.932],
                          [-32.795, -13.455],
                          [-32.795, -30.272],
                          [-30.272, -32.795],
                          [-13.455, -32.795],
                          [-10.932, -30.272],
                          [-13.455, -27.75],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 3',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'mm',
                    mm: 1,
                    nm: 'Merge Paths 1',
                    mn: 'ADBE Vector Filter - Merge',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.6196078431372549, 0.06274509803921569, 1],
                      ix: 4,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 5,
                    },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [33.045, 33.045],
                      ix: 2,
                    },
                    a: {
                      a: 0,
                      k: [0, 0],
                      ix: 1,
                    },
                    s: {
                      a: 0,
                      k: [100, 100],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7,
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4,
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5,
                    },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 5,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 61,
            st: 0,
            bm: 0,
          },
        ],
        markers: [],
      }
    })
    const size = computed(() => props.size)
    const cssSize = computed(() => {
      if (regex.test(size.value.toString())) {
        return `${size.value}px`
      }
      return size.value
    })
    const containerStyleBind = computed(() => ({
      width: cssSize.value,
      height: cssSize.value,
    }))
    const container = ref<HTMLElement | undefined>(undefined)
    onMounted(() => {
      if (container.value) {
        lottie.loadAnimation({
          container: container.value,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: animationData.value,
        })
      }
    })
    return {
      containerStyleBind,
      container,
      lottieColor,
    }
  },
})
</script>
